import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()
import { IPriceRequestOrder } from '@/types/interfaces'
import axiosInstance from '@/axios'
import { CreateOrderType } from '@/types/types'

class CartAPIModel {
  async createCart(farmer_product_id: number, quantity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.create(Microservices.Core, APIModels.Cart, {
        farmer_product_id,
        quantity,
      })
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCartData(
    page = 1,
    perPage = 100,
    filter = [['cart_items.exists()', 'eq', true]],
    withs: string[] = ['cart_items', 'address'],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(Microservices.Core, APIModels.Cart)
        .setPagination(perPage, page)
        .withs(withs)
        .filter(filter)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getGroupedItems(boolean = false, operator: 'OR' | 'AND'): Promise<any> {
    const data = {
      is_zero_quantity: boolean,
      is_deleted: boolean,
      farmer_product_operator: operator,
    }
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Cart,
        'getGroupedItems',
        data,
      )
        .withs(['cartItems.farmerProduct.priceOptions'])
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCartDataStore(
    page = 1,
    perPage = 100,
    withs: string[] = ['user'],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(Microservices.Core, APIModels.Cart)
        .setPagination(perPage, page)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async deleteCartItem(id: number | string): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.delete(Microservices.Core, APIModels.Cart, {
        id,
      })
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async checkItems(): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Cart,
        'checkItems',
        {},
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // оформление заказа из корзины
  async createOrderFromCart(orderData: CreateOrderType): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Cart,
        'createOrders',
        orderData,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // оформление заказа из аукциона/редукциона
  async createOtherOrder(attributes: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/Cart/createOrder`,
          { attributes: attributes },
          {
            headers: {
              Authorization: `${cookies.get('core')}`,
            },
          },
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async createPriceRequestOrder(orderData: IPriceRequestOrder): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Cart,
        'createOrder',
        orderData,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async deleteManyCartItem(ids: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.deleteMany(
        Microservices.Core,
        APIModels.Cart,
        { ids },
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateCartItem(
    id: number | string,
    quantity: number | string,
  ): Promise<any> {
    const data = {
      id: id,
      quantity: quantity,
    }
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.update(
        Microservices.Core,
        APIModels.Cart,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const CartAPI = new CartAPIModel()
export { CartAPI }
