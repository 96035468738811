import { useCookies } from 'vue3-cookies'
import axios from 'axios'
import { useJWTDecode } from '@/composables/useJWTDecode'
import { Roles } from '@/types/enums'
import { removeQuotes } from '@/assets/calendar/helpers'
import { clearCookies } from '@/helpers/scripts'
import { useRouter } from 'vue-router'

export function useAuth() {
  const { cookies } = useCookies()
  const { getCoreCookie } = useJWTDecode()
  const router = useRouter()

  const register = (userData: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      return axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/register`, userData)
        .then((res: any) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const logout = () => {
    clearCookies()

    localStorage.removeItem('umt')
    localStorage.removeItem('ust')
    localStorage.removeItem('umrt')

    router.push('/sign-in').then()
  }

  const relogin = async () => {
    await axios({
      url: `${process.env.VUE_APP_BASE_URL}/auth/User/loginToService`,
      method: 'POST',
      data: {
        service_name: 'core',
        token: localStorage.getItem('umt'),
      },
    })
      .then((response) => {
        cookies.remove('core')
        document.cookie = `core=${response.data.action_result.data};max-age=604800;`
      })
      .catch((error) => {
        return error
      })
  }
  const login = (
    userData: { password: string; captcha: null; email: string },
    redirectAfter = false,
  ) => {
    return new Promise((resolve, reject) => {
      return axios
        .post(`${process.env.VUE_APP_BASE_URL}/auth/User/login`, userData)
        .then(async (data) => {
          loginToService(data, false, 'auth').then()
          loginToService(data, false, 'chat').then()
          loginToService(data, false, 'onec').then()
          loginToService(data, redirectAfter, 'core').then()

          localStorage.setItem(
            'umt',
            data.data.action_result.data.user_master_token,
          )
          localStorage.setItem(
            'umrt',
            data.data.action_result.data.user_master_refresh_token,
          )
          resolve(data)
        })
        .catch(async (err) => {
          const error = {
            email: 'Неверная почта или пароль',
            password: 'Неверная почта или пароль',
            blockLogin: false,
            confirmEmail: false,
            deleteToken: false,
          }

          if (
            err?.response.data?.action_error?.message ===
            'User email is not confirmed'
          ) {
            console.error('User email is not confirmed')
            await router.push('/confirm-email')
          }

          if (err[0]?.data?.action_error?.code === 422) {
            error.blockLogin = true
          }

          if (err[0]?.data?.action_error?.code === 405) {
            error.confirmEmail = true
          }

          if (
            err[0]?.data?.action_error?.message === 'Unable to decode token!'
          ) {
            logout()
          }

          reject(error)
        })
    })
  }

  const loginToService = (
    data: any,
    redirectAfter = false,
    service_name: string,
  ): Promise<any> => {
    let umt = data?.data?.action_result?.data?.user_master_token
    umt = removeQuotes(umt)

    const loginData = {
      service_name: service_name,
      token: umt,
    }
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/auth/User/loginToService`,
          loginData,
        )
        .then((data) => {
          // @ts-ignore
          const actionRes = data.data.action_result.data
          /**
           *    UST поставили неделю, также же как и время UMT, так как egal interceptor отслеживает
           *    ошибку именно на истечение ust, а не на его отсутсвтвие, и поэтому одновременно с
           *    умиранием umt будет отправляться и обновление ust.
           */
          document.cookie = `${service_name}=${actionRes};max-age=604800;`
          if (redirectAfter) {
            // Для админа после авторизации происходит редирект на страницу пользователей
            if (getCoreCookie()?.roles?.includes(Roles.Admin)) {
              router.push('/admin/users').then()
            } else {
              router.push('/').then()
            }
          }
          resolve(data)
        })
        .catch((error) => {
          reject(error)
          throw new Error(error.message)
        })
    })
  }

  const loginIntoService = (
    master_token: any,
    service_name: string,
  ): Promise<any> => {
    const cleanUmt = removeQuotes(master_token)

    const loginData = {
      service_name: service_name,
      token: cleanUmt,
    }
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/auth/User/loginToService`,
          loginData,
        )
        .then((data) => {
          const token = data?.data?.action_result?.data
          document.cookie = `${service_name}=${token};max-age=604800;`
          resolve(data)
        })
        .catch((error) => {
          reject(error)
          throw new Error(error.message)
        })
    })
  }

  const isLoggedIn = () => {
    return cookies.get('core')
  }

  return {
    register,
    login,
    loginToService,
    logout,
    isLoggedIn,
    relogin,
    loginIntoService,
  }
}
