import { useCookies } from 'vue3-cookies'
import axiosInstance from '@/axios'
import { GetItemsResponse, IUser } from '@/types/interfaces'
import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'

const { cookies } = useCookies()

class TokenAPIModel {
  async createInvite(
    email: string,
    companyID: string,
    role: string,
  ): Promise<any> {
    const data = {
      email: email,
      type: role,
      company_id: companyID,
    }

    const headers = {
      headers: {
        Authorization: cookies.get('auth'),
      },
    }

    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/auth/Token/createInvite`,
          {
            attributes: data,
          },
          headers,
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async checkInvite(token: string): Promise<any> {
    const data = {
      token: token,
    }

    const headers = {
      headers: {
        Authorization: cookies.get('auth'),
      },
    }

    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/auth/Token/checkInvite`,
          {
            attributes: data,
          },
          headers,
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async getItems(
    page = 1,
    perPage = 10,
    filters: (string | number)[][] = [],
    withs: string[] = [],
  ): Promise<GetItemsResponse<IUser>> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(Microservices.Auth, APIModels.Token)
        .withs(withs)
        .setPagination(perPage, page)
        .filter(filters)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const TokenAPI = new TokenAPIModel()
export { TokenAPI }
