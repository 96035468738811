import axios from 'axios'
import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import axiosInstance from '@/axios'

class ChatAPIModel {
  checkUsers(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL.replace('/api', '')}/chatify/api/User/check`,
          {
            users: data,
          },
        )
        .then((response) => {
          resolve(response?.data?.action_result?.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  createUserInChat(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL.replace('/api', '')}/chatify/api/User/create`,
          data,
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  createChannel(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL.replace('/api', '')}/chatify/api/Channel/create`,
          data,
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getFarmer(id: number, withs: string[] = []): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.Farmer,
        id,
      )
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCustomer(id: number, withs: string[] = []): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.Customer,
        id,
      )
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const ChatAPI = new ChatAPIModel()
export { ChatAPI }
