import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { Document, DocumentCreate } from '@/types/types'
import { useCookies } from 'vue3-cookies'
import axiosInstance from '@/axios'

const { cookies } = useCookies()

class DocumentAPIModel {
  async createManyDocuments(data: Document[] | DocumentCreate[]): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.createMany(
        Microservices.Core,
        APIModels.CompanyDocument,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async createDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.create(
        Microservices.Core,
        APIModels.Document,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async uploadDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Document,
        'upload',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async uploadCompanyDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.CompanyDocument,
        'upload',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCustomDocuments(
    filters: any = [],
    perPage = 10,
    withs: string[] = ['users', 'orders', 'order_documents', 'document_users'],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: {
          Authorization: cookies.get('core'),
        },
      }
      axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/Document/getItems`,
          {},
          headers,
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async getCustomDocuments2(
    filters: any = [],
    perPage = 10,
    withs: string[] = ['users', 'orders', 'order_documents', 'document_users'],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: {
          Authorization: cookies.get('core'),
        },
      }
        axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/CompanyDocument/getItems`,
          {
            filters,
          },
          headers,
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  }

  async getDocuments(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Document,
      )
        .filter(filters)
        .setPagination(perPage, 1)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCompanyDocuments(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.CompanyDocument,
      )
        .filter(filters)
        .setPagination(perPage, 1)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getDocument(id: string | number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.Document,
        id,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCompanyDocument(id: string | number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.CompanyDocument,
        id,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.update(
        Microservices.Core,
        APIModels.Document,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getOrdersDocuments(
    filters: any = [],
    perPage = 10,
    withs: string[] = ['order'],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.OrderDocument,
      )
        .filter(filters)
        .setPagination(perPage, 1)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateManyDocuments(data: Partial<Document>[]): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.updateMany(
        Microservices.Core,
        APIModels.CompanyDocument,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const DocumentAPI = new DocumentAPIModel()
export { DocumentAPI }
