import axios from 'axios'
import { deleteCookie, getCookie } from '@egalteam/framework'

const axiosInstance = axios.create()
const domain = process.env.VUE_APP_BASE_URL

const getUMTToken = () => localStorage.getItem('umt') || ''
const getUMRTToken = () => localStorage.getItem('umrt') || ''

const setUMTToken = (token: string) => localStorage.setItem('umt', token)
const setUMRTToken = (token: string) => localStorage.setItem('umrt', token)

let initial_request: any = null
let isRefreshing = false
let token_refresh_callbacks: any[] = []

const add_token_refresh_callback = (cb: any) => {
  token_refresh_callbacks.push(cb)
}

const call_token_refresh_callback = () => {
  token_refresh_callbacks.forEach((cb) => {
    cb()
  })
}
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config } = error
    const original_request = config
    if (!original_request.url.includes('login')) {
      initial_request = config
    }
    if (
      error.response?.data.action_error.code === 401 &&
      error.response?.data.action_error.internal_code === 'ust_expired'
    ) {
      const serviceName = config?.url?.split('/')[4]
      if (!isRefreshing) {
        isRefreshing = true
        refresh_access_token(serviceName).then(() => {
          isRefreshing = false
        })
      }

      return new Promise((resolve, reject) => {
        add_token_refresh_callback(() => {
          initial_request.headers['Authorization'] = getCookie(serviceName)
          resolve(axiosInstance(initial_request))
          token_refresh_callbacks = []
        })
      })
    } else {
      return Promise.reject(error)
    }
  },
)

async function refresh_access_token(serviceName: string) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.data.action_error.internal_code === 'umt_expired') {
        refresh_master_token('auth')
          .then(() => {
            refresh_access_token(serviceName)
          })
          .catch((error) => {
            return error
          })
      } else {
        return error
      }
    },
  )

  if (getUMRTToken() && getUMTToken()) {
    delete axiosInstance.defaults.headers.common.Authorization
    axiosInstance({
      url: `${domain}/auth/User/loginToService`,
      method: 'POST',
      data: {
        service_name: serviceName,
        token: getUMTToken(),
      },
    })
      .then((response) => {
        deleteCookie(serviceName)
        const newToken = response.data.action_result.data
        set_cookie(serviceName, newToken)
        call_token_refresh_callback()
        return newToken
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

async function refresh_master_token(serviceName: string) {
  if (getUMRTToken()) {
    delete axiosInstance.defaults.headers.common.Authorization
    return axiosInstance({
      url: `${domain}/${serviceName}/User/refreshUserMasterToken`,
      method: 'POST',
      data: {
        token: getUMRTToken(),
      },
    })
      .then((response) => {
        setUMRTToken(response.data.action_result.data.user_master_refresh_token)
        setUMTToken(response.data.action_result.data.user_master_token)
      })
      .catch((error) => {
        localStorage.removeItem('umrt')
        return Promise.reject('Session expired')
      })
  } else {
    return Promise.reject('Session expired')
  }
}

const set_cookie = (serviceName: string, token: string) => {
  if (token) {
    document.cookie = `${serviceName}=${token};max-age=604800;`
  }
}

export default axiosInstance
